import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Zu, Text, PrevNext, Br } from '../../components/Elements.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "地藏菩萨本愿经"
    }}>{`地藏菩萨本愿经`}</h1>
    <PrevNext prev='/dz/v1' next='/dz/v3' mdxType="PrevNext" /> <Br mdxType="Br" />
    <h3 {...{
      "id": "第二卷-分身集会品"
    }}>{`第二卷 分身集会品`}</h3>
    <Text mdxType="Text">
      <Zu yin='r&uacute;' zi='如' mdxType="Zu" />
      <Zu yin='ěr' zi='尔' mdxType="Zu" />
      <Zu yin='shí' zi='时' mdxType="Zu" />
      <Zu yin='bǎi' zi='百' mdxType="Zu" />
      <Zu yin='qiān' zi='千' mdxType="Zu" />
      <Zu yin='wàn' zi='万' mdxType="Zu" />
      <Zu yin='yì' zi='亿' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='kě' zi='可' mdxType="Zu" />
      <Zu yin='sī' zi='思' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='kě' zi='可' mdxType="Zu" />
      <Zu yin='yì' zi='议' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='kě' zi='可' mdxType="Zu" />
      <Zu yin='liánɡ' zi='量' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='kě' zi='可' mdxType="Zu" />
      <Zu yin='shuō' zi='说' mdxType="Zu" />
      <Zu yin='wú' zi='无' mdxType="Zu" />
      <Zu yin='liànɡ' zi='量' mdxType="Zu" />
      <Zu yin='ā' zi='阿' mdxType="Zu" />
      <Zu yin='sēnɡ' zi='僧' mdxType="Zu" />
      <Zu yin='qí' zi='祇' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='jiè' zi='界' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='suǒ' zi='所' mdxType="Zu" />
      <Zu yin='yǒu' zi='有' mdxType="Zu" />
      <Zu yin='dì' zi='地' mdxType="Zu" />
      <Zu yin='yù' zi='狱' mdxType="Zu" />
      <Zu yin='chù' zi='处' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='fēn' zi='分' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='dì' zi='地' mdxType="Zu" />
      <Zu yin='zànɡ' zi='藏' mdxType="Zu" />
      <Zu yin='pú' zi='菩' mdxType="Zu" />
      <Zu yin='sà' zi='萨' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='jù' zi='俱' mdxType="Zu" />
      <Zu yin='lái' zi='来' mdxType="Zu" />
      <Zu yin='jí' zi='集' mdxType="Zu" />
      <Zu yin='zài' zi='在' mdxType="Zu" />
      <Zu yin='dāo' zi='忉' mdxType="Zu" />
      <Zu yin='lì' zi='利' mdxType="Zu" />
      <Zu yin='tiān' zi='天' mdxType="Zu" />
      <Zu yin='ɡōnɡ' zi='宫' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='yǐ' zi='以' mdxType="Zu" />
      <Zu yin='rú' zi='如' mdxType="Zu" />
      <Zu yin='lái' zi='来' mdxType="Zu" />
      <Zu yin='shén' zi='神' mdxType="Zu" />
      <Zu yin='lì' zi='力' mdxType="Zu" />
      <Zu yin='ɡù' zi='故' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ɡè' zi='各' mdxType="Zu" />
      <Zu yin='yǐ' zi='以' mdxType="Zu" />
      <Zu yin='fānɡ' zi='方' mdxType="Zu" />
      <Zu yin='miàn' zi='面' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='yǔ' zi='与' mdxType="Zu" />
      <Zu yin='zhū' zi='诸' mdxType="Zu" />
      <Zu yin='dé' zi='得' mdxType="Zu" />
      <Zu yin='xiè' zi='解' mdxType="Zu" />
      <Zu yin='tuō' zi='脱' mdxType="Zu" />
      <Zu yin='cónɡ' zi='从' mdxType="Zu" />
      <Zu yin='yè' zi='业' mdxType="Zu" />
      <Zu yin='dào' zi='道' mdxType="Zu" />
      <Zu yin='chū' zi='出' mdxType="Zu" />
      <Zu yin='zhě' zi='者' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='yì' zi='亦' mdxType="Zu" />
      <Zu yin='ɡè' zi='各' mdxType="Zu" />
      <Zu yin='yǒu' zi='有' mdxType="Zu" />
      <Zu yin='qiān' zi='千' mdxType="Zu" />
      <Zu yin='wàn' zi='万' mdxType="Zu" />
      <Zu yin='yì' zi='亿' mdxType="Zu" />
      <Zu yin='nuó' zi='那' mdxType="Zu" />
      <Zu yin='yóu' zi='由' mdxType="Zu" />
      <Zu yin='tā' zi='他' mdxType="Zu" />
      <Zu yin='shù' zi='数' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ɡònɡ' zi='共' mdxType="Zu" />
      <Zu yin='chí' zi='持' mdxType="Zu" />
      <Zu yin='xiānɡ' zi='香' mdxType="Zu" />
      <Zu yin='huā' zi='华' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='lái' zi='来' mdxType="Zu" />
      <Zu yin='ɡònɡ' zi='供' mdxType="Zu" />
      <Zu yin='yǎnɡ' zi='养' mdxType="Zu" />
      <Zu yin='fó' zi='佛' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='bǐ' zi='彼' mdxType="Zu" />
      <Zu yin='zhū' zi='诸' mdxType="Zu" />
      <Zu yin='tónɡ' zi='同' mdxType="Zu" />
      <Zu yin='lái' zi='来' mdxType="Zu" />
      <Zu yin='děnɡ' zi='等' mdxType="Zu" />
      <Zu yin='bèi' zi='辈' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='jiē' zi='皆' mdxType="Zu" />
      <Zu yin='yīn' zi='因' mdxType="Zu" />
      <Zu yin='dì' zi='地' mdxType="Zu" />
      <Zu yin='zànɡ' zi='藏' mdxType="Zu" />
      <Zu yin='pú' zi='菩' mdxType="Zu" />
      <Zu yin='sà' zi='萨' mdxType="Zu" />
      <Zu yin='jiào' zi='教' mdxType="Zu" />
      <Zu yin='huà' zi='化' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='yǒnɡ' zi='永' mdxType="Zu" />
      <Zu yin='bú' zi='不' mdxType="Zu" />
      <Zu yin='tuì' zi='退' mdxType="Zu" />
      <Zu yin='zhuǎn' zi='转' mdxType="Zu" />
      <Zu yin='yú' zi='于' mdxType="Zu" />
      <Zu yin='ā' zi='阿' mdxType="Zu" />
      <Zu yin='nòu' zi='耨' mdxType="Zu" />
      <Zu yin='duō' zi='多' mdxType="Zu" />
      <Zu yin='luó' zi='罗' mdxType="Zu" />
      <Zu yin='sān' zi='三' mdxType="Zu" />
      <Zu yin='miǎo' zi='藐' mdxType="Zu" />
      <Zu yin='sān' zi='三' mdxType="Zu" />
      <Zu yin='pú' zi='菩' mdxType="Zu" />
      <Zu yin='tí' zi='提' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='shì' zi='是' mdxType="Zu" />
      <Zu yin='zhū' zi='诸' mdxType="Zu" />
      <Zu yin='zhònɡ' zi='众' mdxType="Zu" />
      <Zu yin='děnɡ' zi='等' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='jiǔ' zi='久' mdxType="Zu" />
      <Zu yin='yuǎn' zi='远' mdxType="Zu" />
      <Zu yin='jié' zi='劫' mdxType="Zu" />
      <Zu yin='lái' zi='来' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='liú' zi='流' mdxType="Zu" />
      <Zu yin='lànɡ' zi='浪' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='生' mdxType="Zu" />
      <Zu yin='sǐ' zi='死' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='liù' zi='六' mdxType="Zu" />
      <Zu yin='dào' zi='道' mdxType="Zu" />
      <Zu yin='shòu' zi='受' mdxType="Zu" />
      <Zu yin='kǔ' zi='苦' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='zàn' zi='暂' mdxType="Zu" />
      <Zu yin='wú' zi='无' mdxType="Zu" />
      <Zu yin='xiū' zi='休' mdxType="Zu" />
      <Zu yin='xi' zi='息' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='yǐ' zi='以' mdxType="Zu" />
      <Zu yin='dì' zi='地' mdxType="Zu" />
      <Zu yin='zànɡ' zi='藏' mdxType="Zu" />
      <Zu yin='pú' zi='菩' mdxType="Zu" />
      <Zu yin='sà' zi='萨' mdxType="Zu" />
      <Zu yin='ɡuǎnɡ' zi='广' mdxType="Zu" />
      <Zu yin='dà' zi='大' mdxType="Zu" />
      <Zu yin='cí' zi='慈' mdxType="Zu" />
      <Zu yin='bēi' zi='悲' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='shēn' zi='深' mdxType="Zu" />
      <Zu yin='shì' zi='誓' mdxType="Zu" />
      <Zu yin='yuàn' zi='愿' mdxType="Zu" />
      <Zu yin='ɡù' zi='故' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ɡè' zi='各' mdxType="Zu" />
      <Zu yin='huò' zi='获' mdxType="Zu" />
      <Zu yin='ɡuǒ' zi='果' mdxType="Zu" />
      <Zu yin='zhènɡ' zi='证' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='jì' zi='既' mdxType="Zu" />
      <Zu yin='zhì' zi='至' mdxType="Zu" />
      <Zu yin='dāo' zi='忉' mdxType="Zu" />
      <Zu yin='lì' zi='利' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='xīn' zi='心' mdxType="Zu" />
      <Zu yin='huái' zi='怀' mdxType="Zu" />
      <Zu yin='yǒnɡ' zi='踊' mdxType="Zu" />
      <Zu yin='yuè' zi='跃' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='zhān' zi='瞻' mdxType="Zu" />
      <Zu yin='yǎnɡ' zi='仰' mdxType="Zu" />
      <Zu yin='rú' zi='如' mdxType="Zu" />
      <Zu yin='lái' zi='来' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='mù' zi='目' mdxType="Zu" />
      <Zu yin='bú' zi='不' mdxType="Zu" />
      <Zu yin='zàn' zi='暂' mdxType="Zu" />
      <Zu yin='shě' zi='舍' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='ěr' zi='尔' mdxType="Zu" />
      <Zu yin='shí' zi='时' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='zūn' zi='尊' mdxType="Zu" />
      <Zu yin='shū' zi='舒' mdxType="Zu" />
      <Zu yin='jīn' zi='金' mdxType="Zu" />
      <Zu yin='sè' zi='色' mdxType="Zu" />
      <Zu yin='bì' zi='臂' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='mó' zi='摩' mdxType="Zu" />
      <Zu yin='bǎi' zi='百' mdxType="Zu" />
      <Zu yin='qiān' zi='千' mdxType="Zu" />
      <Zu yin='wàn' zi='万' mdxType="Zu" />
      <Zu yin='yì' zi='亿' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='kě' zi='可' mdxType="Zu" />
      <Zu yin='sī' zi='思' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='kě' zi='可' mdxType="Zu" />
      <Zu yin='yì' zi='议' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='kě' zi='可' mdxType="Zu" />
      <Zu yin='liánɡ' zi='量' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='kě' zi='可' mdxType="Zu" />
      <Zu yin='shuō' zi='说' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='wú' zi='无' mdxType="Zu" />
      <Zu yin='liànɡ' zi='量' mdxType="Zu" />
      <Zu yin='ā' zi='阿' mdxType="Zu" />
      <Zu yin='sēnɡ' zi='僧' mdxType="Zu" />
      <Zu yin='qí' zi='祇' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='jiè' zi='界' mdxType="Zu" />
      <Zu yin='zhū' zi='诸' mdxType="Zu" />
      <Zu yin='fēn' zi='分' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='dì' zi='地' mdxType="Zu" />
      <Zu yin='zànɡ' zi='藏' mdxType="Zu" />
      <Zu yin='pú' zi='菩' mdxType="Zu" />
      <Zu yin='sà' zi='萨' mdxType="Zu" />
      <Zu yin='mó' zi='摩' mdxType="Zu" />
      <Zu yin='hē' zi='诃' mdxType="Zu" />
      <Zu yin='sà' zi='萨' mdxType="Zu" />
      <Zu yin='dǐnɡ' zi='顶' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ér' zi='而' mdxType="Zu" />
      <Zu yin='zuò' zi='作' mdxType="Zu" />
      <Zu yin='shì' zi='是' mdxType="Zu" />
      <Zu yin='yán' zi='言' mdxType="Zu" />
      <Zu yin='：' zi='：' mdxType="Zu" />
      <Zu yin='wú' zi='吾' mdxType="Zu" />
      <Zu yin='yú' zi='于' mdxType="Zu" />
      <Zu yin='wǔ' zi='五' mdxType="Zu" />
      <Zu yin='zhuó' zi='浊' mdxType="Zu" />
      <Zu yin='è' zi='恶' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='jiào' zi='教' mdxType="Zu" />
      <Zu yin='huà' zi='化' mdxType="Zu" />
      <Zu yin='rú' zi='如' mdxType="Zu" />
      <Zu yin='shì' zi='是' mdxType="Zu" />
      <Zu yin='ɡānɡ' zi='刚' mdxType="Zu" />
      <Zu yin='qiánɡ' zi='强' mdxType="Zu" />
      <Zu yin='zhònɡ' zi='众' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='生' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='lìnɡ' zi='令' mdxType="Zu" />
      <Zu yin='xīn' zi='心' mdxType="Zu" />
      <Zu yin='tiáo' zi='调' mdxType="Zu" />
      <Zu yin='fú' zi='伏' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='shě' zi='舍' mdxType="Zu" />
      <Zu yin='xié' zi='邪' mdxType="Zu" />
      <Zu yin='ɡuī' zi='归' mdxType="Zu" />
      <Zu yin='zhènɡ' zi='正' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='shí' zi='十' mdxType="Zu" />
      <Zu yin='yǒu' zi='有' mdxType="Zu" />
      <Zu yin='yī' zi='一' mdxType="Zu" />
      <Zu yin='èr' zi='二' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='shànɡ' zi='尚' mdxType="Zu" />
      <Zu yin='è' zi='恶' mdxType="Zu" />
      <Zu yin='xí' zi='习' mdxType="Zu" />
      <Zu yin='zài' zi='在' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
    
      <Zu yin='wú' zi='吾' mdxType="Zu" />
      <Zu yin='yì' zi='亦' mdxType="Zu" />
      <Zu yin='fēn' zi='分' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='qiān' zi='千' mdxType="Zu" />
      <Zu yin='bǎi' zi='百' mdxType="Zu" />
      <Zu yin='yì' zi='亿' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ɡuǎnɡ' zi='广' mdxType="Zu" />
      <Zu yin='shè' zi='设' mdxType="Zu" />
      <Zu yin='fānɡ' zi='方' mdxType="Zu" />
      <Zu yin='biàn' zi='便' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='yǒu' zi='有' mdxType="Zu" />
      <Zu yin='lì' zi='利' mdxType="Zu" />
      <Zu yin='ɡēn' zi='根' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='wén' zi='闻' mdxType="Zu" />
      <Zu yin='jí' zi='即' mdxType="Zu" />
      <Zu yin='xìn' zi='信' mdxType="Zu" />
      <Zu yin='shòu' zi='受' mdxType="Zu" />
      <Zu yin='；' zi='；' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='yǒu' zi='有' mdxType="Zu" />
      <Zu yin='shàn' zi='善' mdxType="Zu" />
      <Zu yin='ɡuǒ' zi='果' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='qín' zi='勤' mdxType="Zu" />
      <Zu yin='quàn' zi='劝' mdxType="Zu" />
      <Zu yin='chénɡ' zi='成' mdxType="Zu" />
      <Zu yin='jiù' zi='就' mdxType="Zu" />
      <Zu yin='；' zi='；' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='yǒu' zi='有' mdxType="Zu" />
      <Zu yin='àn' zi='暗' mdxType="Zu" />
      <Zu yin='dùn' zi='钝' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='jiǔ' zi='久' mdxType="Zu" />
      <Zu yin='huà' zi='化' mdxType="Zu" />
      <Zu yin='fānɡ' zi='方' mdxType="Zu" />
      <Zu yin='ɡuī' zi='归' mdxType="Zu" />
      <Zu yin='；' zi='；' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='yǒu' zi='有' mdxType="Zu" />
      <Zu yin='yè' zi='业' mdxType="Zu" />
      <Zu yin='zhònɡ' zi='重' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='生' mdxType="Zu" />
      <Zu yin='jìnɡ' zi='敬' mdxType="Zu" />
      <Zu yin='yǎnɡ' zi='仰' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='rú' zi='如' mdxType="Zu" />
      <Zu yin='shì' zi='是' mdxType="Zu" />
      <Zu yin='děnɡ' zi='等' mdxType="Zu" />
      <Zu yin='bèi' zi='辈' mdxType="Zu" />
      <Zu yin='zhònɡ' zi='众' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='生' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ɡè' zi='各' mdxType="Zu" />
      <Zu yin='ɡè' zi='各' mdxType="Zu" />
      <Zu yin='chā' zi='差' mdxType="Zu" />
      <Zu yin='bié' zi='别' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='fēn' zi='分' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='dù' zi='度' mdxType="Zu" />
      <Zu yin='tuō' zi='脱' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
    
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='nán' zi='男' mdxType="Zu" />
      <Zu yin='zǐ' zi='子' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='nǚ' zi='女' mdxType="Zu" />
      <Zu yin='rén' zi='人' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='tiān' zi='天' mdxType="Zu" />
      <Zu yin='lónɡ' zi='龙' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='shén' zi='神' mdxType="Zu" />
      <Zu yin='ɡuǐ' zi='鬼' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='shān' zi='山' mdxType="Zu" />
      <Zu yin='lín' zi='林' mdxType="Zu" />
      <Zu yin='chuān' zi='川' mdxType="Zu" />
      <Zu yin='yuán' zi='原' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='hé' zi='河' mdxType="Zu" />
      <Zu yin='chí' zi='池' mdxType="Zu" />
      <Zu yin='quán' zi='泉' mdxType="Zu" />
      <Zu yin='jǐnɡ' zi='井' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='lì' zi='利' mdxType="Zu" />
      <Zu yin='jí' zi='及' mdxType="Zu" />
      <Zu yin='yú' zi='于' mdxType="Zu" />
      <Zu yin='rén' zi='人' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='xī' zi='悉' mdxType="Zu" />
      <Zu yin='jiē' zi='皆' mdxType="Zu" />
      <Zu yin='dù' zi='度' mdxType="Zu" />
      <Zu yin='tuō' zi='脱' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
    
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='tiān' zi='天' mdxType="Zu" />
      <Zu yin='dì' zi='帝' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='fàn' zi='梵' mdxType="Zu" />
      <Zu yin='wánɡ' zi='王' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='zhuǎn' zi='转' mdxType="Zu" />
      <Zu yin='lún' zi='轮' mdxType="Zu" />
      <Zu yin='wánɡ' zi='王' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='jū' zi='居' mdxType="Zu" />
      <Zu yin='shì' zi='士' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='ɡuó' zi='国' mdxType="Zu" />
      <Zu yin='wánɡ' zi='王' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='zǎi' zi='宰' mdxType="Zu" />
      <Zu yin='fǔ' zi='辅' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='ɡuān' zi='官' mdxType="Zu" />
      <Zu yin='shǔ' zi='属' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='bǐ' zi='比' mdxType="Zu" />
      <Zu yin='qiū' zi='丘' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='bǐ' zi='比' mdxType="Zu" />
      <Zu yin='qiū' zi='丘' mdxType="Zu" />
      <Zu yin='ní' zi='尼' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='yōu' zi='优' mdxType="Zu" />
      <Zu yin='pó' zi='婆' mdxType="Zu" />
      <Zu yin='sè' zi='塞' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='yōu' zi='优' mdxType="Zu" />
      <Zu yin='pó' zi='婆' mdxType="Zu" />
      <Zu yin='yí' zi='夷' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='nǎi' zi='乃' mdxType="Zu" />
      <Zu yin='zhì' zi='至' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='声' mdxType="Zu" />
      <Zu yin='wén' zi='闻' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='luó' zi='罗' mdxType="Zu" />
      <Zu yin='hàn' zi='汉' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='pì' zi='辟' mdxType="Zu" />
      <Zu yin='zhī' zi='支' mdxType="Zu" />
      <Zu yin='fó' zi='佛' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='pú' zi='菩' mdxType="Zu" />
      <Zu yin='sà' zi='萨' mdxType="Zu" />
      <Zu yin='děnɡ' zi='等' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='、' zi='、' mdxType="Zu" />
      <Zu yin='ér' zi='而' mdxType="Zu" />
      <Zu yin='yǐ' zi='以' mdxType="Zu" />
      <Zu yin='huà' zi='化' mdxType="Zu" />
      <Zu yin='dù' zi='度' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
    
      <Zu yin='fēi' zi='非' mdxType="Zu" />
      <Zu yin='dàn' zi='但' mdxType="Zu" />
      <Zu yin='fó' zi='佛' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='dú' zi='独' mdxType="Zu" />
      <Zu yin='xiàn' zi='现' mdxType="Zu" />
      <Zu yin='qí' zi='其' mdxType="Zu" />
      <Zu yin='qián' zi='前' mdxType="Zu" />
      <Zu yin='rǔ' zi='汝' mdxType="Zu" />
      <Zu yin='ɡuān' zi='观' mdxType="Zu" />
      <Zu yin='wú' zi='吾' mdxType="Zu" />
      <Zu yin='lèi' zi='累' mdxType="Zu" />
      <Zu yin='jié' zi='劫' mdxType="Zu" />
      <Zu yin='qín' zi='勤' mdxType="Zu" />
      <Zu yin='kǔ' zi='苦' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='dù' zi='度' mdxType="Zu" />
      <Zu yin='tuō' zi='脱' mdxType="Zu" />
      <Zu yin='rú' zi='如' mdxType="Zu" />
      <Zu yin='shì' zi='是' mdxType="Zu" />
      <Zu yin='děnɡ' zi='等' mdxType="Zu" />
      <Zu yin='nán' zi='难' mdxType="Zu" />
      <Zu yin='huà' zi='化' mdxType="Zu" />
      <Zu yin='ɡānɡ' zi='刚' mdxType="Zu" />
      <Zu yin='qiánɡ' zi='强' mdxType="Zu" />
      <Zu yin='zuì' zi='罪' mdxType="Zu" />
      <Zu yin='kǔ' zi='苦' mdxType="Zu" />
      <Zu yin='zhònɡ' zi='众' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='生' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='qí' zi='其' mdxType="Zu" />
      <Zu yin='yǒu' zi='有' mdxType="Zu" />
      <Zu yin='wèi' zi='未' mdxType="Zu" />
      <Zu yin='tiáo' zi='调' mdxType="Zu" />
      <Zu yin='fú' zi='伏' mdxType="Zu" />
      <Zu yin='zhě' zi='者' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='suí' zi='随' mdxType="Zu" />
      <Zu yin='yè' zi='业' mdxType="Zu" />
      <Zu yin='bào' zi='报' mdxType="Zu" />
      <Zu yin='yìnɡ' zi='应' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='ruò' zi='若' mdxType="Zu" />
      <Zu yin='duò' zi='堕' mdxType="Zu" />
      <Zu yin='è' zi='恶' mdxType="Zu" />
      <Zu yin='qù' zi='趣' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='shòu' zi='受' mdxType="Zu" />
      <Zu yin='dà' zi='大' mdxType="Zu" />
      <Zu yin='kǔ' zi='苦' mdxType="Zu" />
      <Zu yin='shí' zi='时' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='rǔ' zi='汝' mdxType="Zu" />
      <Zu yin='dānɡ' zi='当' mdxType="Zu" />
      <Zu yin='yì' zi='忆' mdxType="Zu" />
      <Zu yin='niàn' zi='念' mdxType="Zu" />
      <Zu yin='wú' zi='吾' mdxType="Zu" />
      <Zu yin='zài' zi='在' mdxType="Zu" />
      <Zu yin='dāo' zi='忉' mdxType="Zu" />
      <Zu yin='lì' zi='利' mdxType="Zu" />
      <Zu yin='tiān' zi='天' mdxType="Zu" />
      <Zu yin='ɡōnɡ' zi='宫' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='yīn' zi='殷' mdxType="Zu" />
      <Zu yin='qín' zi='勤' mdxType="Zu" />
      <Zu yin='fù' zi='付' mdxType="Zu" />
      <Zu yin='zhǔ' zi='嘱' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
    
      <Zu yin='lìnɡ' zi='令' mdxType="Zu" />
      <Zu yin='suō' zi='娑' mdxType="Zu" />
      <Zu yin='pó' zi='婆' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='jiè' zi='界' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='zhì' zi='至' mdxType="Zu" />
      <Zu yin='mí' zi='弥' mdxType="Zu" />
      <Zu yin='lè' zi='勒' mdxType="Zu" />
      <Zu yin='chū' zi='出' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='yǐ' zi='已' mdxType="Zu" />
      <Zu yin='lái' zi='来' mdxType="Zu" />
      <Zu yin='zhònɡ' zi='众' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='生' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='xī' zi='悉' mdxType="Zu" />
      <Zu yin='shǐ' zi='使' mdxType="Zu" />
      <Zu yin='xiè' zi='解' mdxType="Zu" />
      <Zu yin='tuō' zi='脱' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='yǒnɡ' zi='永' mdxType="Zu" />
      <Zu yin='lí' zi='离' mdxType="Zu" />
      <Zu yin='zhū' zi='诸' mdxType="Zu" />
      <Zu yin='kǔ' zi='苦' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='yù' zi='遇' mdxType="Zu" />
      <Zu yin='fó' zi='佛' mdxType="Zu" />
      <Zu yin='shòu' zi='授' mdxType="Zu" />
      <Zu yin='jì' zi='记' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ěr' zi='尔' mdxType="Zu" />
      <Zu yin='shí' zi='时' mdxType="Zu" />
      <Zu yin='zhū' zi='诸' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='jiè' zi='界' mdxType="Zu" />
      <Zu yin='fēn' zi='分' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='dì' zi='地' mdxType="Zu" />
      <Zu yin='zànɡ' zi='藏' mdxType="Zu" />
      <Zu yin='pú' zi='菩' mdxType="Zu" />
      <Zu yin='sà' zi='萨' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ɡònɡ' zi='共' mdxType="Zu" />
      <Zu yin='fù' zi='复' mdxType="Zu" />
      <Zu yin='yì' zi='一' mdxType="Zu" />
      <Zu yin='xínɡ' zi='形' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='tì' zi='涕' mdxType="Zu" />
      <Zu yin='lèi' zi='泪' mdxType="Zu" />
      <Zu yin='āi' zi='哀' mdxType="Zu" />
      <Zu yin='liàn' zi='恋' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='bái' zi='白' mdxType="Zu" />
      <Zu yin='qí' zi='其' mdxType="Zu" />
      <Zu yin='fó' zi='佛' mdxType="Zu" />
      <Zu yin='yán' zi='言' mdxType="Zu" />
      <Zu yin='：' zi='：' mdxType="Zu" />
      <Zu yin='wǒ' zi='我' mdxType="Zu" />
      <Zu yin='cónɡ' zi='从' mdxType="Zu" />
      <Zu yin='jiǔ' zi='久' mdxType="Zu" />
      <Zu yin='yuǎn' zi='远' mdxType="Zu" />
      <Zu yin='jié' zi='劫' mdxType="Zu" />
      <Zu yin='lái' zi='来' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ménɡ' zi='蒙' mdxType="Zu" />
      <Zu yin='fó' zi='佛' mdxType="Zu" />
      <Zu yin='jiē' zi='接' mdxType="Zu" />
      <Zu yin='yǐn' zi='引' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='shǐ' zi='使' mdxType="Zu" />
      <Zu yin='huò' zi='获' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='kě' zi='可' mdxType="Zu" />
      <Zu yin='sī' zi='思' mdxType="Zu" />
      <Zu yin='yì' zi='议' mdxType="Zu" />
      <Zu yin='shén' zi='神' mdxType="Zu" />
      <Zu yin='lì' zi='力' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='jù' zi='具' mdxType="Zu" />
      <Zu yin='dà' zi='大' mdxType="Zu" />
      <Zu yin='zhì' zi='智' mdxType="Zu" />
      <Zu yin='huì' zi='慧' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='wǒ' zi='我' mdxType="Zu" />
      <Zu yin='suǒ' zi='所' mdxType="Zu" />
      <Zu yin='fēn' zi='分' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='biàn' zi='遍' mdxType="Zu" />
      <Zu yin='mǎn' zi='满' mdxType="Zu" />
      <Zu yin='bǎi' zi='百' mdxType="Zu" />
      <Zu yin='qiān' zi='千' mdxType="Zu" />
      <Zu yin='wàn' zi='万' mdxType="Zu" />
      <Zu yin='yì' zi='亿' mdxType="Zu" />
      <Zu yin='hénɡ' zi='恒' mdxType="Zu" />
      <Zu yin='hé' zi='河' mdxType="Zu" />
      <Zu yin='shā' zi='沙' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='jiè' zi='界' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='měi' zi='每' mdxType="Zu" />
      <Zu yin='yī' zi='一' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='jiè' zi='界' mdxType="Zu" />
      <Zu yin='huà' zi='化' mdxType="Zu" />
      <Zu yin='bǎi' zi='百' mdxType="Zu" />
      <Zu yin='qiān' zi='千' mdxType="Zu" />
      <Zu yin='wàn' zi='万' mdxType="Zu" />
      <Zu yin='yì' zi='亿' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='měi' zi='每' mdxType="Zu" />
      <Zu yin='yì' zi='一' mdxType="Zu" />
      <Zu yin='shēn' zi='身' mdxType="Zu" />
      <Zu yin='dù' zi='度' mdxType="Zu" />
      <Zu yin='bǎi' zi='百' mdxType="Zu" />
      <Zu yin='qiān' zi='千' mdxType="Zu" />
      <Zu yin='wàn' zi='万' mdxType="Zu" />
      <Zu yin='yì' zi='亿' mdxType="Zu" />
      <Zu yin='rén' zi='人' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='lìnɡ' zi='令' mdxType="Zu" />
      <Zu yin='ɡuī' zi='归' mdxType="Zu" />
      <Zu yin='jìnɡ' zi='敬' mdxType="Zu" />
      <Zu yin='sān' zi='三' mdxType="Zu" />
      <Zu yin='bǎo' zi='宝' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='yǒnɡ' zi='永' mdxType="Zu" />
      <Zu yin='lí' zi='离' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='生' mdxType="Zu" />
      <Zu yin='sǐ' zi='死' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='zhì' zi='至' mdxType="Zu" />
      <Zu yin='niè' zi='涅' mdxType="Zu" />
      <Zu yin='pán' zi='槃' mdxType="Zu" />
      <Zu yin='lè' zi='乐' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='dàn' zi='但' mdxType="Zu" />
      <Zu yin='yú' zi='于' mdxType="Zu" />
      <Zu yin='fó' zi='佛' mdxType="Zu" />
      <Zu yin='fǎ' zi='法' mdxType="Zu" />
      <Zu yin='zhōnɡ' zi='中' mdxType="Zu" />
      <Zu yin='suǒ' zi='所' mdxType="Zu" />
      <Zu yin='wéi' zi='为' mdxType="Zu" />
      <Zu yin='shàn' zi='善' mdxType="Zu" />
      <Zu yin='shì' zi='事' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='yī' zi='一' mdxType="Zu" />
      <Zu yin='máo' zi='毛' mdxType="Zu" />
      <Zu yin='yī' zi='一' mdxType="Zu" />
      <Zu yin='dī' zi='渧' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='yī' zi='一' mdxType="Zu" />
      <Zu yin='shā' zi='沙' mdxType="Zu" />
      <Zu yin='yī' zi='一' mdxType="Zu" />
      <Zu yin='chén' zi='尘' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='huò' zi='或' mdxType="Zu" />
      <Zu yin='háo' zi='毫' mdxType="Zu" />
      <Zu yin='fā' zi='发' mdxType="Zu" />
      <Zu yin='xǔ' zi='许' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='wǒ' zi='我' mdxType="Zu" />
      <Zu yin='jiàn' zi='渐' mdxType="Zu" />
      <Zu yin='dù' zi='度' mdxType="Zu" />
      <Zu yin='tuō' zi='脱' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='shǐ' zi='使' mdxType="Zu" />
      <Zu yin='huò' zi='获' mdxType="Zu" />
      <Zu yin='dà' zi='大' mdxType="Zu" />
      <Zu yin='lì' zi='利' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='wéi' zi='唯' mdxType="Zu" />
      <Zu yin='yuàn' zi='愿' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='zūn' zi='尊' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='yǐ' zi='以' mdxType="Zu" />
      <Zu yin='hòu' zi='后' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='è' zi='恶' mdxType="Zu" />
      <Zu yin='yè' zi='业' mdxType="Zu" />
      <Zu yin='zhònɡ' zi='众' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='生' mdxType="Zu" />
      <Zu yin='wéi' zi='为' mdxType="Zu" />
      <Zu yin='lǜ' zi='虑' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='rú' zi='如' mdxType="Zu" />
      <Zu yin='shì' zi='是' mdxType="Zu" />
      <Zu yin='sān' zi='三' mdxType="Zu" />
      <Zu yin='bái' zi='白' mdxType="Zu" />
      <Zu yin='fó' zi='佛' mdxType="Zu" />
      <Zu yin='yán' zi='言' mdxType="Zu" />
      <Zu yin='：' zi='：' mdxType="Zu" />
      <Zu yin='wéi' zi='唯' mdxType="Zu" />
      <Zu yin='yuàn' zi='愿' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='zūn' zi='尊' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='bù' zi='不' mdxType="Zu" />
      <Zu yin='yǐ' zi='以' mdxType="Zu" />
      <Zu yin='hòu' zi='后' mdxType="Zu" />
      <Zu yin='shì' zi='世' mdxType="Zu" />
      <Zu yin='è' zi='恶' mdxType="Zu" />
      <Zu yin='yè' zi='业' mdxType="Zu" />
      <Zu yin='zhònɡ' zi='众' mdxType="Zu" />
      <Zu yin='shēnɡ' zi='生' mdxType="Zu" />
      <Zu yin='wéi' zi='为' mdxType="Zu" />
      <Zu yin='lǜ' zi='虑' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='ěr' zi='尔' mdxType="Zu" />
      <Zu yin='shí' zi='时' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='fó' zi='佛' mdxType="Zu" />
      <Zu yin='zàn' zi='赞' mdxType="Zu" />
      <Zu yin='dì' zi='地' mdxType="Zu" />
      <Zu yin='zànɡ' zi='藏' mdxType="Zu" />
      <Zu yin='pú' zi='菩' mdxType="Zu" />
      <Zu yin='sà' zi='萨' mdxType="Zu" />
      <Zu yin='yán' zi='言' mdxType="Zu" />
      <Zu yin='：' zi='：' mdxType="Zu" />
      <Zu yin='shàn' zi='善' mdxType="Zu" />
      <Zu yin='zāi' zi='哉' mdxType="Zu" />
      <Zu yin='！' zi='！' mdxType="Zu" />
      <Zu yin='shàn' zi='善' mdxType="Zu" />
      <Zu yin='zāi' zi='哉' mdxType="Zu" />
      <Zu yin='！' zi='！' mdxType="Zu" />
      <Zu yin='wú' zi='吾' mdxType="Zu" />
      <Zu yin='zhù' zi='助' mdxType="Zu" />
      <Zu yin='rǔ' zi='汝' mdxType="Zu" />
      <Zu yin='xǐ' zi='喜' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
      <Zu yin='rǔ' zi='汝' mdxType="Zu" />
      <Zu yin='nénɡ' zi='能' mdxType="Zu" />
      <Zu yin='chénɡ' zi='成' mdxType="Zu" />
      <Zu yin='jiù' zi='就' mdxType="Zu" />
      <Zu yin='jiǔ' zi='久' mdxType="Zu" />
      <Zu yin='yuǎn' zi='远' mdxType="Zu" />
      <Zu yin='jié' zi='劫' mdxType="Zu" />
      <Zu yin='lái' zi='来' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='fā' zi='发' mdxType="Zu" />
      <Zu yin='hónɡ' zi='弘' mdxType="Zu" />
      <Zu yin='shì' zi='誓' mdxType="Zu" />
      <Zu yin='yuàn' zi='愿' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='ɡuǎnɡ' zi='广' mdxType="Zu" />
      <Zu yin='dù' zi='度' mdxType="Zu" />
      <Zu yin='jiānɡ' zi='将' mdxType="Zu" />
      <Zu yin='bì' zi='毕' mdxType="Zu" />
      <Zu yin='，' zi='，' mdxType="Zu" />
      <Zu yin='jí' zi='即' mdxType="Zu" />
      <Zu yin='zhènɡ' zi='证' mdxType="Zu" />
      <Zu yin='pú' zi='菩' mdxType="Zu" />
      <Zu yin='tí' zi='提' mdxType="Zu" />
      <Zu yin='。' zi='。' mdxType="Zu" />
    </Text>
    <PrevNext prev='/dz/v1' next='/dz/v3' mdxType="PrevNext" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      